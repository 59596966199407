/**
 * Created by Joerg on 27.07.2017.
 */

// rxjs
import 'rxjs/Rx';
import { Observable,BehaviorSubject,Subscription } from 'rxjs/Rx';

// angular
import { Injectable } from '@angular/core';

import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { JwtHelper } from 'angular2-jwt';

import { MnBrandService } from "@mn/core"

let ls_user = "test";
let url_login = '/auth/login/?format=json';
//let url_logout = '/auth/logout/?format=json';
let no_user = {
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    authenticated: false,
    superuser: false,
    permissions: [],
    token: null
};


@Injectable()
export class MnAuthService {

    private mJwtHelper:JwtHelper = new JwtHelper();
    private mUser:any = no_user;
    private mUserChange:BehaviorSubject<any> = new BehaviorSubject(no_user);
    private mAuthenticating:Observable<any> = null;

    constructor(private mHttp:Http) {
        console.log("MnAuthService created");
        this.userLoad();
        console.log(this.mUser);
        this.mUserChange.next(this.mUser);
    }

    private userLoad() {
        var user = localStorage.getItem(ls_user);
        if (user == null) {
            console.log("LocalStorage User is null");
            this.resetUserLS();
            return;
        }
        this.mUser = JSON.parse(user);

        console.log("Backend token expiration date:",this.mJwtHelper.getTokenExpirationDate(this.mUser.token));
        if (this.mJwtHelper.isTokenExpired(this.mUser.token)) {
            console.log("LocalStorage User token expired");
            this.resetUserLS();
            return;
        }
    }

    private resetUserLS() {
        localStorage.removeItem(ls_user);
        this.resetUser();
        this.mUserChange.next(this.mUser);
    }

    private resetUser() {
        this.mUser = no_user;
    }

    public login(username:string, password:string):Observable<any> {
        if (this.mAuthenticating !== null) return;

        let body = JSON.stringify({ username: username, password: password });
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        this.mAuthenticating = this.mHttp.post(MnBrandService.raw().config.backend + url_login, body, options).map(
            res => res.json()
        ).map((data:any) => {
            console.log(data);
            return {
                username: data.user.username,
                firstname: data.user.first_name,
                lastname: data.user.last_name,
                email: data.user.email,
                authenticated: true,
                superuser: data.user.is_superuser,
                permissions: data.user.user_permissions,
                token: data.token
            };
        }).share();
        this.mAuthenticating.subscribe(
            (data:any) => {
                console.log(data);
                this.mUser = data;
                localStorage.setItem(ls_user, JSON.stringify(this.mUser));
                this.mUserChange.next(this.mUser);
                this.mAuthenticating = null;
            },
            err => {
                console.error(err);
                this.resetUserLS();
                this.mAuthenticating = null;
            },
            () => {
                console.log('User authentication complete');
            }
        );
        return this.mAuthenticating;
    }

    public logout() {
        this.resetUserLS();
    }

    public subscribe(callback):Subscription {
        return this.mUserChange.subscribe(callback);
    }

    public user(): Observable<any> {
        if (this.mAuthenticating !== null) return this.mAuthenticating;
        return Observable.of(this.mUser);
    }
}

