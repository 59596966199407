/**
 * Created by Joerg on 18.07.2017.
 */

import {Component} from '@angular/core';

//import {AuthHttp} from 'angular2-jwt';

import {MnBackend} from '@mn/core';


@Component({
    selector: 'mn-test-auth',
    template: `<mn-test-login-form></mn-test-login-form>`
})
export class MnTestAuth {
    constructor(private mAuthHttp:MnBackend) {

        console.log(mAuthHttp);

        /*this.mAuthHttp.get("/chemtunes/chem/attribute/").map(
            res => res.json()
        ).subscribe(
            data => console.log(data)
        )*/
    }

}
