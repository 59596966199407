/**
 * Created by Joerg on 17.11.2017.
 */

// rxjs
import 'rxjs/Rx';
import { Subscription } from 'rxjs/Rx';

// angular
import { Directive,Input, ElementRef } from '@angular/core';

// mn
import { MnUnsubscribe, MnTextService } from "@mn/core";

//
import { MnAuthService } from "./MnAuthService"

@Directive({
    selector: '[mn-text-or-user]'
})
@MnUnsubscribe()
export class MnTextOrUser {

    mOnLangChange:Subscription;
    mOnAuthChange:Subscription;

    mParams:any = null;
    @Input('params') set params(value: string) {
        this.mParams = value;
        this.onChange();
    }

    mKey:string = null;
    @Input('mn-text-or-user') set key(value: string) {
        this.mKey = value;
        this.onChange();
    }

    constructor(private mElRef:ElementRef, private mTextService:MnTextService, private mAuthService:MnAuthService) {
        this.mOnLangChange = mTextService.onLanguageChange((language)=> this.onChange());
        this.mOnAuthChange = mAuthService.subscribe((language)=> this.onChange());
    }

    private onChange() {
        this.mAuthService.user().subscribe((user) => {
            if (user.authenticated) {
                MnTextOrUser.update(this.mElRef,user.username);
            } else {
                if (this.mKey == null) return;
                MnTextOrUser.update(this.mElRef,<string> this.mTextService.text(this.mKey,this.mParams));
            }
        });
    }

    static update(elref:ElementRef, text:string) {
        if(elref.nativeElement.innerHTML !== undefined) {
            elref.nativeElement.innerHTML = text;
        } else if(elref.nativeElement.textContent !== undefined) {
            elref.nativeElement.textContent = text;
        } else {
            elref.nativeElement.data = text;
        }
    }

    ngOnDestroy() {}

}

