/**
 * Created by Joerg on 17.11.2017.
 */

// angular
import { Component } from '@angular/core';

// uirouter
import { Transition } from '@uirouter/core';

//
import { MnAuthService } from "./MnAuthService"
import { MnLoginBase } from "./MnLoginBase"


@Component({
    selector: 'mn-login',
    templateUrl: './MnLogin.html',
})
export class MnLogin extends MnLoginBase {

    public mModel: any = {};

    constructor(mAuthService:MnAuthService, mTransition:Transition) {
        super(mAuthService,mTransition);
    }
}

