/**
 * Created by Joerg on 27.07.2017.
 */

// rxjs
import 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/Rx';

// angular
import { Injectable,Injector } from '@angular/core';

// mn
import { MnBrandService,MnTextService } from "@mn/core"

//
import { MnAuthService } from "./MnAuthService";
import { passAuthService } from "./MnAuthModule";

let brand_authenticated = [];
function fixBrandAuthenticatedObservable(text1:any,resolve:string) {
    for (var k1 in text1) {
        if(!text1.hasOwnProperty(k1)) continue;
        if (k1.endsWith("authenticated?")) {
            let text2 = text1[k1];
            if (typeof text2 !== 'string') {
                console.error("error in brand.ts text authenticated? object should be string",text2);
                continue;
            }
            console.log(text2);
            let split = text2.split(':');
            if (split.length != 2) {
                console.error("error in brand.ts text authenticated? syntax, should be 'textref:textref'",text2);
            } else {
                text1[k1] = new BehaviorSubject("");
                brand_authenticated.push({
                    observable: text1[k1],
                    values: split,
                    resolve: resolve
                });
            }
        }
    }
}
function fixBrandAuthenticatedText(text) {
    console.log(text);
    for (var k0 in text) {
        if(!text.hasOwnProperty(k0)) continue;
        let text1 = text[k0];
        fixBrandAuthenticatedObservable(text1,'t');
    }
}

export function fixBrandAuthenticated() {
    fixBrandAuthenticatedText(MnBrandService.raw().text);
    fixBrandAuthenticatedObservable(MnBrandService.raw().anchors,'a');
    console.log("brand_authenticated",brand_authenticated);
}

@Injectable()
export class MnAuthBrandObservableService {

    constructor(private mAuthService:MnAuthService, private mInjector:Injector) {
        console.log("MnAuthBrandObservableService created");

        passAuthService(mAuthService);

        this.mAuthService.subscribe((user)=>{

            setTimeout(() => {

                const text_service: MnTextService = this.mInjector.get(MnTextService);
                const brand_service: MnBrandService = this.mInjector.get(MnBrandService);
                let resolves = {
                    't': (k)=>text_service.text(k),
                    'a': (k)=>{
                        let ak = brand_service.anchor(k);
                        if (ak.href === false) return k;
                        return ak;
                    }
                };

                let resolver = function(key:string,resolve):any {
                    if (key.startsWith('@')) {
                        return user[key.slice(1)];
                    }
                    return resolve(key);
                };

                let j = user.authenticated ? 0 : 1;
                for (let i = 0, l = brand_authenticated.length; i < l; i++) {
                    let a = brand_authenticated[i];
                    a.observable.next( resolver(a.values[j],resolves[a.resolve]) );
                }

            });

        })
    }



}

