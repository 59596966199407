/**
 * Created by joerg on 5/3/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable, Subject, BehaviorSubject } from 'rxjs/Rx';

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpModule, Http, RequestOptions } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// uirouter
import { UIRouter } from '@uirouter/core';

// angularjwt
import {AuthHttp, AuthConfig } from 'angular2-jwt';

// mn.route
import {MnCoreModule, MnBackend, MnBrandService, MnTextService} from "@mn/core"
import { MnRouteModule } from "@mn/route"

//
import { MnTestAuth } from "./MnTestAuth"
import { MnTestLoginForm } from "./MnTestLoginForm"
import { MnAuthService } from "./MnAuthService"
import { MnLogin } from "./MnLogin";
import { MnTextOrUser } from "./MnTextOrUser";
import { MnAuthBrandObservableService,fixBrandAuthenticated } from "./MnAuthBrandObservableService";

let MnAuthBackendServiceFactory = (http: AuthHttp, brand:MnBrandService) => {
    return new MnBackend(http,brand);
};


// configure angular-jwt
function authHttpServiceFactory(http: Http, options: RequestOptions, auth:MnAuthService) {
    return new AuthHttp(
        new AuthConfig({
            tokenGetter: (() => auth.user().map(data => data.token).toPromise()),
            headerPrefix: 'JWT',
            globalHeaders: [{'Content-Type':'application/json'},{'Accept':'application/json'}]
        }),
        http,
        options,
    );
}


let auth_workaround = new BehaviorSubject(null);
export function passAuthService(auth_service:MnAuthService) {
    auth_workaround.next(auth_service);
}

// register an auth hook in @mn/route to catch transitions to protected states
export function requiresAuthHook(router: UIRouter) {
    const transitionService = router.transitionService;
    const requiresAuthCriteria = {
        to: (state) => state.data && state.data.auth
    };
    const redirectToLogin = (transition) => {
        var redirect_state = 'test.auth';
        let brand = MnBrandService.raw();
        if (brand.auth &&  brand.auth.login) {
            redirect_state = brand.auth.login;
        }
        if (brand.data && brand.data.route && brand.data.route.login) {
            redirect_state = brand.data.route.login;
        }
        //const auth_service: MnAuthService = transition.injector().get(MnAuthService);
        const $state = transition.router.stateService;
        /*return auth_service.user().map(
            (user) => {
                if (!user.authenticated) {
                    return $state.target(redirect_state, undefined, { location: false });
                }
                return true;
            }
        ).toPromise();*/

        return new Promise<any>(function(resolve, reject) {
            auth_workaround.subscribe((auth_service)=> {
               if (auth_service !== null) {
                   auth_service.user().subscribe(
                       (user) => {
                           if (!user.authenticated) {
                               //return $state.target(redirect_state, undefined, { location: false });
                               resolve($state.target(redirect_state, undefined, { location: false }));
                           }
                           resolve(true);
                       }
                   );
               }
            });
        })
    };
    // Register the "requires auth" hook with the TransitionsService
    transitionService.onBefore(requiresAuthCriteria, redirectToLogin, {priority: 10});
}

MnRouteModule.addConfig(requiresAuthHook);

@NgModule({
    imports: [
        CommonModule, HttpModule, FormsModule, MnCoreModule, MnRouteModule
    ],
    exports: [
        MnLogin, MnTextOrUser
    ],
    declarations: [
        MnTestAuth, MnTestLoginForm, MnLogin, MnTextOrUser
    ],
    entryComponents: [
        MnTestAuth, MnTestLoginForm, MnLogin
    ],
    providers: [
    ]
})
export class MnAuthModule {

    constructor(private mAuthBrandObservableService: MnAuthBrandObservableService) {
        console.log("MnAuthModule created");
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnAuthModule,
            providers: [
                MnAuthService,
                MnAuthBrandObservableService,
                {
                    provide: AuthHttp,
                    useFactory: authHttpServiceFactory,
                    deps: [Http, RequestOptions,MnAuthService]
                },
                {
                    provide: MnBackend,
                    useFactory: MnAuthBackendServiceFactory,
                    deps: [AuthHttp,MnBrandService]
                }
            ]
        };
    }
}

export class mn_auth {
    static configure():any {
        fixBrandAuthenticated();
        return [
            MnAuthModule.forRoot()
        ];
    }
}


