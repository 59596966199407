/**
 * Created by Joerg on 28.07.2017.
 */

//angular
import { Component } from '@angular/core';

//uirouter
import { Transition } from '@uirouter/core';

//mn
import { MnAuthService } from "./MnAuthService"
import { MnLoginBase } from "./MnLoginBase"

@Component({
    selector: 'mn-test-login-form',
    templateUrl: './MnTestLoginForm.html'
})
export class MnTestLoginForm extends MnLoginBase {

    public mModel: any = {};

    constructor(mAuthService:MnAuthService, mTransition:Transition) {
        super(mAuthService,mTransition);
    }
}
