/**
 * Created by Joerg on 28.07.2017.
 */

// uirouter
import { Transition } from '@uirouter/core';

// mn
import { MnBrandService } from "@mn/core"

//
import { MnAuthService } from "./MnAuthService"

function getLastState ($transition$: Transition): any {
    if ($transition$.redirectedFrom() != null) {
        return $transition$.redirectedFrom().targetState();
    }
    const $state = $transition$.router.stateService;
    if ($transition$.from().name !== '') {
        return $state.target($transition$.from(), $transition$.params('from'));
    }
    let brand = MnBrandService.raw();
    if (brand.data && brand.data.route && brand.data.route.home) {
        return $state.target(brand.data.route.home);
    }
    //return $state.target('home');
}

export class MnLoginBase {

    public isAuthenticating:boolean = false;
    public errorMessage:string = "";
    public errorDetails:string = "";

    constructor(public mAuthService:MnAuthService, public mTransition:Transition) {}

    public login(username:string,password:string) {
        if (this.isAuthenticating) return;
        this.isAuthenticating = true;
        const returnToOriginalState = () => {
            var rt:any = getLastState(this.mTransition);
            const state = rt.state();
            const params = rt.params();
            const options = Object.assign({}, rt.options(), { reload: true });
            this.mTransition.router.stateService.go(state, params, options);
        };
        this.mAuthService.login(username, password)
            .subscribe(
                data => {
                    this.isAuthenticating = false;
                    this.errorMessage = "";
                    this.errorDetails = ""
                    console.log("Authentication Success");
                    returnToOriginalState();
                },
                error => {
                    console.log(error.json());
                    this.errorDetails = `Server response status ${error.status} (${error.statusText})`;
                    let errorj = error.json();
                    if (errorj.non_field_errors && errorj.non_field_errors.constructor === Array) {
                        this.errorDetails+= ': ';
                        this.errorDetails+= errorj.non_field_errors.join(" ");
                    }
                    this.isAuthenticating = false;
                    this.errorMessage = "Authentication not successfull";
                    console.log("Authentication Error",error);
                },
                () => {
                    console.log("Authentication attempt finished");
                });
    }

}

